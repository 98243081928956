import { Dispatch, ReactChild } from 'react';
import { Reducer, ActionWithPayload } from 'types/reducer';

export enum ActionTypes {
  SET_PROFILE_DATA = 'SET_PROFILE_DATA',
  SET_IS_UNAUTHORIZED = 'SET_IS_UNAUTHORIZED',
  SET_HAS_PERMISSIONS_CHANGED = 'SET_HAS_PERMISSIONS_CHANGED',
}

export type Service = {
  authorities: Record<string, boolean>
};

export type UserProfile = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  services: Record<string, Service>;
};

export type State = {
  userProfile: UserProfile;
  hasPermissionsChanged: boolean;
  isUnauthenticated: boolean;

};

type Value = UserProfile | boolean;

export type AppReducer = Reducer<State, ActionTypes, Value>;

export type Action = ActionWithPayload<ActionTypes, Value>;

export type AppDispatch = Dispatch<Action>;

export type ContextType = { appState: State, dispatch: AppDispatch};

export type StoreProviderProps = {
  children: ReactChild;
};

export type AppStateHookData = {
  tenantId: string;
  userTypeId: string;
  userProfile: UserProfile;
  isUnauthenticated: boolean;
  hasPermissionsChanged: boolean;
  getProfileData: <T>(key: string, defaultValue: T) => T,
};

export type UseAppState = () => AppStateHookData;

export type StoreType = {
  dispatch: AppDispatch
};
