import { FC, lazy, Suspense } from 'react';
import {
  getFromLocalStorage,
  MixpanelProvider,
  ServiceName,
  TenantType,
} from '@divisionsinc/cc-shared';
import {
  Route,
  Routes as RouterRoutes,
  BrowserRouter,
} from 'react-router-dom';
import 'App.css';
import LoginSuccess from 'components/pages/Login/LoginSuccess';
import { CentralSpinner } from 'components/shared/Spinner';
import { FE_URLS } from 'Routes/urls';

const Login = lazy(() => import('components/pages/Login'));
const NotFound = lazy(() => import('components/pages/NotFound'));

export const Routes: FC = () => {
  const params = new URLSearchParams(window.location.search);
  const redirectUri = params.get('redirectUri') || getFromLocalStorage('redirectUri') as string;
  return (
    <BrowserRouter>
      <MixpanelProvider
        tenantType={redirectUri?.includes('fast-accept')
          ? TenantType.PROVIDER : TenantType.USER}
        serviceName={ServiceName.AUTH_SERVICE}
        enableTracking={process.env.REACT_APP_ENABLE_MIXPANEL === 'true'}
        token={process.env.REACT_APP_MIXPANEL_KEY}
      >
        <Suspense fallback={<CentralSpinner />}>
          <RouterRoutes>
            <Route path="/" element={<Login />} />
            {/* TODO remove login success */}
            <Route path={FE_URLS.loginSuccess} element={<LoginSuccess />} />
            <Route path="*" element={<NotFound />} />
          </RouterRoutes>
        </Suspense>
      </MixpanelProvider>
    </BrowserRouter>
  );
};
