import {
  Action, ActionTypes, AppReducer, State,
} from './types';

export const initialState = {
  userProfile: {} as State['userProfile'],
  hasPermissionsChanged: false,
  isUnauthenticated: false,
};

export const reducer: AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_PROFILE_DATA: return {
      ...state,
      userProfile: action.payload as State['userProfile'],
    };
    case ActionTypes.SET_HAS_PERMISSIONS_CHANGED: return {
      ...state,
      hasPermissionsChanged: action.payload as State['hasPermissionsChanged'],
    };
    case ActionTypes.SET_IS_UNAUTHORIZED: return {
      ...state,
      isUnauthenticated: action.payload as State['isUnauthenticated'],
    };
    default: return state;
  }
};

export const setUserProfile = (profile: State['userProfile']): Action => ({
  type: ActionTypes.SET_PROFILE_DATA,
  payload: profile,
});

export const setPermissionChanged = (hasPermissionsChanged: State['hasPermissionsChanged']): Action => ({
  type: ActionTypes.SET_HAS_PERMISSIONS_CHANGED,
  payload: hasPermissionsChanged,
});

export const setIsUnauthenticated = (isUnauthenticated: State['isUnauthenticated']):
Action => ({
  type: ActionTypes.SET_IS_UNAUTHORIZED,
  payload: isUnauthenticated,
});

export * from './types';
