import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { trackingHeadersInterceptor, DatadogProvider } from '@divisionsinc/cc-shared';
import './index.css';
import axios from 'axios';
import { StoreProvider } from 'store';
import { LocaleContextProvider } from './LocaleContext';
import App from './App';

axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  trackingHeadersInterceptor(),
);

ReactDOM.render(
  <StrictMode>
    <StoreProvider>
      <LocaleContextProvider>
        <DatadogProvider
          token={process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN}
          applicationId={process.env.REACT_APP_DATA_DOG_APP_ID as string}
          serviceName="control-center-auth"
        >
          <App />
        </DatadogProvider>
      </LocaleContextProvider>
    </StoreProvider>
  </StrictMode>,
  document.getElementById('root'),
);
