import {
  FC, useContext, useEffect,
} from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from '@mui/material';
import { Theme } from '@divisionsinc/cc-shared';
import { en, fr } from 'translations';
import { LocaleContext, LocaleContextType } from 'LocaleContext';
import { Routes } from './Routes';
import 'App.css';

type Value = {
  [key: string]: string
};

type Messages = {
  [key: string]: Value
};

const messages: Messages = {
  en,
  fr,
};

const App: FC = () => {
  const [locale] = useContext(LocaleContext) as LocaleContextType;

  useEffect(() => {
    window.addEventListener('storage', (event) => {
      if (event.key === 'logoutEvent') {
        window.location.href = `${window.location.origin}/login`;
      }
    });
  }, []);

  return (
    <IntlProvider locale={locale as string} messages={messages[locale]}>
      <ThemeProvider theme={Theme}>
        <Routes />
      </ThemeProvider>
    </IntlProvider>
  );
};

export default App;
