import { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import { getPaletteColor } from '@divisionsinc/cc-shared';

export const Spinner = styled(CircularProgress)`
  && {
    color: ${getPaletteColor('primary.main')};
  }
`;

export const CentralSpinner: FC = () => (
  // TODO: move calc(100vh - 64px) to AppContainer
  <Box minHeight="calc(100vh - 64px)" width="100%" display="flex" justifyContent="center" alignItems="center" alignContent="center">
    <Spinner />
  </Box>
);
