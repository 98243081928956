import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { FE_URLS } from 'Routes/urls';

const LoginSuccess = (): ReactElement => {
  const navigate = useNavigate();

  useEffect(() => {
    const redirectPath = localStorage.getItem('redirectPath') || '';
    localStorage.removeItem('redirectPath');
    const path = redirectPath.length > 0 ? redirectPath : FE_URLS.dashboard;
    navigate(path);
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: '100vh' }}
    >
      <CircularProgress />
    </Grid>
  );
};

export default LoginSuccess;
