const rolePath = '/user-management/roles';
const userPath = '/user-management/users';

export const FE_URLS = {
  userManagement: {
    roles: (): string => rolePath,
    users: (): string => userPath,
    addRole: (): string => `${rolePath}/add-role`,
    editRole: (roleId: string): string => `${rolePath}/edit-role/${roleId}`,
    viewRole: (roleId: string): string => `${rolePath}/view-role/${roleId}`,
    usersInRole: (roleId: string, roleName: string): string => `${rolePath}/${roleId}:${roleName}/users`,
    addUser: (): string => `${userPath}/add-user`,
    viewUser: (userId: string): string => `${userPath}/view-user/${userId}`,
    editUser: (userId: string): string => `${userPath}/edit-user/${userId}`,
  },
  dashboard: '/',
  loginSuccess: '/login/success',
};
